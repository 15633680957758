import "../scss/app.scss";
import "../img/rajevac.svg"

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}

const navButton = document.querySelector(".menu-button");
const navMenu = document.querySelector(".main-menu");
const menuIconWrapper = document.querySelector(".menu-icon-wrapper");
const menuIcon = document.querySelector(".menu-icon");
const menuIconLabel = menuIconWrapper.querySelector(".sr-only");
const originalLabel = menuIconLabel.innerHTML;
const toggledLabel = "Close main menu";
let isToggled = false;

navButton.addEventListener("click", function () {

    const currentAriaLabel = navButton.getAttribute("aria-label");
    const toggleAriaLabel = currentAriaLabel === "Open main menu" ? "Close main menu" : "Open main menu";
    navButton.setAttribute("aria-label", toggleAriaLabel);

    const currentAriaExpanded = navButton.getAttribute("aria-expanded");
    const toggleAriaExpanded = currentAriaExpanded === "false" ? "true" : "false";
    navButton.setAttribute("aria-expanded", toggleAriaExpanded);

    if (!isToggled) {
        menuIconLabel.innerHTML = toggledLabel;
        navButton.setAttribute("aria-label", "Close main menu");
        navButton.setAttribute("aria-expanded", "true");
        isToggled = true;
    } else {
        menuIconLabel.innerHTML = originalLabel;
        isToggled = false;
    }

    navMenu.classList.toggle("menu-open");
    menuIcon.classList.toggle("menu-icon-open");

});